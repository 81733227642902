import { GatsbyFullPostPage } from 'src/features/blog'
import { graphql } from 'gatsby'

export default GatsbyFullPostPage

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...FullPostFields
    }
  }
`
